/* eslint-disable @next/next/no-sync-scripts */
import Head from "next/head";
import { ThemeProvider } from "styled-components";
import { FormattedMessage, IntlProvider } from "react-intl";
import { LazyMotion } from "framer-motion";

import useResize from "@hooks/useResize";
import useSmoothScroll from "@hooks/useSmoothScroll";
import { GlobalStyle } from "@setup/theme/GlobalStyles";
import theme from "@setup/theme";
import {
  BASE_PROPS_PAGE_PROPS_KEY,
  i18n
} from "@utils/baseGetStaticProps/consts";
import { removeTrailingSlash } from "@utils/removeTrailingSlash";
import RichText from "@components/atoms/RichText";
import MainLayout from "@components/layout/MainLayout";
import { ModalProvider } from "@components/molecules/modals/ModalBase";
import { HeaderModeContextProvider } from "@contexts/HeaderContext";
import { ContactPageUrlsContextProvider } from "@contexts/ContactPageUrlsContext";
import { CareerPageUrlsContextProvider } from "@contexts/CareerPageUrlsContext";
import { CaseStudyPageUrlsContextProvider } from "@contexts/CaseStudyPageUrlsContext";

import { AppPropsWithBaseProps, BasePageStory } from "./types";
import { useDynamicChunksPreload } from "./hooks";
import * as Styled from "./styles";
import { StoryblokInit } from "./utils";
import StickyButton from "./partials/StickyButton";
import LinkedInTag from "./partials/LinkedInTag";

StoryblokInit();

const loadFeatures = () =>
  import("./features.js").then((res) => res.default);

const PageproWebsiteApp = ({
  Component,
  pageProps: pagePropsProp,
  router: { locale, defaultLocale, isPreview, asPath }
}: AppPropsWithBaseProps) => {
  const { [BASE_PROPS_PAGE_PROPS_KEY]: BASE_PROPS, ...rest } =
    pagePropsProp;
  const {
    translations: messages,
    globalSettings,
    contactPageUrls,
    careerPageUrls,
    caseStudyPageUrls
  } = BASE_PROPS ?? {};
  const { content } = globalSettings || {};

  useResize();
  useSmoothScroll();
  useDynamicChunksPreload();

  const {
    header_menu: headerMenu = [],
    footer_title: footerTitle,
    clutchLink,
    clutchRate,
    clutchReviewsAmount,
    footer_action: footerAction,
    footer_legal_links: footerLegalLinks,
    footer_menu_column: footerMenuColumn,
    footer_social_media: footerSocialMedia,
    footer_background_color: footerBackgroundColor,
    footer_text_color: footerTextColor,
    footer_partnerships: footerPartnerships,
    footer_partnerships_title: footerPartnershipsTitle,
    stickyButton: stickyButtonBlocks
  } = content || {};
  const {
    story: {
      content: {
        isHeaderTransparent = false,
        submenu = [],
        stickyButtonHidden = false
      } = {}
    } = {}
  } = rest as BasePageStory;

  const newestClutchUrl =
    clutchLink?.story?.full_slug || clutchLink?.cached_url;
  const clutchHref =
    clutchLink?.url || newestClutchUrl
      ? removeTrailingSlash(newestClutchUrl)
      : "";
  const rel = clutchLink?.rel;
  const target = clutchLink?.target;

  const {
    text,
    mobileTextAlignment,
    tabletTextAlignment,
    desktopTextAlignment,
    underlineType
  } = footerTitle?.[0] || {};

  const stickyButton = stickyButtonBlocks?.[0];

  return (
    <>
      <Head>
        <link
          rel="icon"
          type="image/png"
          href="/favicon-96x96.png"
          sizes="96x96"
        />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <meta name="apple-mobile-web-app-title" content="Pagepro" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <style
          dangerouslySetInnerHTML={{
            __html: Styled.FontStyles
          }}
        />
      </Head>
      <LazyMotion features={loadFeatures} strict>
        <IntlProvider
          {...{ defaultLocale, messages }}
          locale={locale ?? i18n.defaultLocale}
        >
          <ThemeProvider {...{ theme }}>
            <ModalProvider>
              <HeaderModeContextProvider>
                <CaseStudyPageUrlsContextProvider
                  value={caseStudyPageUrls}
                >
                  <CareerPageUrlsContextProvider
                    value={careerPageUrls}
                  >
                    <ContactPageUrlsContextProvider
                      value={contactPageUrls}
                    >
                      <GlobalStyle />
                      <MainLayout
                        headerProps={{
                          items: headerMenu,
                          isLight: isHeaderTransparent,
                          submenu
                        }}
                        footerProps={{
                          title: text ? (
                            <RichText
                              content={text}
                              {...{
                                mobileTextAlignment,
                                tabletTextAlignment,
                                desktopTextAlignment,
                                underlineType
                              }}
                            />
                          ) : (
                            ""
                          ),
                          clutchLink: {
                            href: clutchHref,
                            rel,
                            target
                          },
                          clutchRate,
                          clutchReviewsAmount,
                          buttonProps: footerAction?.[0],
                          items: footerMenuColumn || [],
                          legalLinks: footerLegalLinks || [],
                          socialMediaItems: footerSocialMedia || [],
                          backgroundColor:
                            footerBackgroundColor?.color,
                          color: footerTextColor?.color,
                          partnerships: footerPartnerships,
                          partnershipsTitle: footerPartnershipsTitle
                        }}
                      >
                        <LinkedInTag />
                        <Component {...rest} />
                      </MainLayout>
                      {isPreview && (
                        <Styled.ExitPreviewLink
                          href={`/api/exit-preview?slug=${
                            asPath.split("?")[0] || "/"
                          }`}
                          $mode="primary"
                        >
                          <FormattedMessage defaultMessage="Exit Preview" />
                        </Styled.ExitPreviewLink>
                      )}
                      {stickyButton && !stickyButtonHidden && (
                        <StickyButton {...stickyButton} />
                      )}
                    </ContactPageUrlsContextProvider>
                  </CareerPageUrlsContextProvider>
                </CaseStudyPageUrlsContextProvider>
              </HeaderModeContextProvider>
            </ModalProvider>
          </ThemeProvider>
        </IntlProvider>
      </LazyMotion>
    </>
  );
};

export default PageproWebsiteApp;
